import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Collapsible,
  Heading,
  Paragraph,
  TextInput,
  Grommet,
  ResponsiveContext,
  Layer,
  Form,
  RadioButtonGroup,
  Select,
  TextArea,
  FormField,
  Spinner,
} from 'grommet';
import * as Icons from 'grommet-icons';


const theme = {
  global: {
    colors: {
      brand: '#eee',
      control: '#eee',
    },
    focus: {
      outline: {
        color: '#ccc',
      },
      border: {
        color: '#ddd'
      }
    }
  },
  card: {
    container: {
      round: "none",
      extend: props => `
        box-shadow: 0 0 30px 11px rgb(0 0 0 / 10%);
        margin: 2em;
        filter: opacity(95%);
        background-color: rgb(255 255 255 / 80%);
      `,
    },
  },
  heading: {
    font: {
      //family: 'Parisienne'
      //family: 'Calligraffitti'
      family: 'Alex Brush'
    }
  },
  paragraph: {
    font: {
      family: 'Raleway'
    }
  },
  radioButton: {
    check: {
      color: '#aaa',
    }
  },
  checkBox: {
    check: {
      extend: props => `
        filter: brightness(70%);
      `,
    }
  },
};

// const AppBar = (props) => (
  
//   <Box
//     tag='header'
//     direction='row'
//     align='center'
//     justify='between'
//     background='brand'
//     pad={{ left: 'medium', right: 'small', vertical: 'small' }}
//     elevation='medium'
//     style={{ zIndex: '1' }}
//     {...props}
//   />
// );


const postRSVP = async (form) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(form)
  };
  const response = await fetch('/api/rsvp', requestOptions);
  try {
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    return false;
  }
}


const childMap = {
  'None': 0,
  'One': 1,
  'Two': 2,
  'Three': 3,
  'Four': 4,
}


function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [locked, setLocked] = useState(localStorage.getItem('a') !== 'a');
  const [passcode, setPasscode] = useState("");
  const [invalidPasscode, setInvalidPasscode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [form, setForm] = useState({
    'first-name': '',
    'last-name': '',
    'partner-first-name': '',
    'partner-last-name': '',
    'child-1-first-name': '',
    'child-2-first-name': '',
    'child-3-first-name': '',
    'child-4-first-name': '',
    'child-1-age': '',
    'child-2-age': '',
    'child-3-age': '',
    'child-4-age': '',
    'attending-option': '',
    email: '',
    attending: '',
    partner: '',
    children: 'None',
    diet: '',
    questions: '',
  });

  return (
    <Grommet theme={theme} full>
      <ResponsiveContext.Consumer>
        {size => (
          <Box fill background="light-1">
            {/* <AppBar style={{zIndex: 2}}>
              <Heading level='3' margin='none'></Heading>
              <Button
                icon={<Icons.Menu />}
                onClick={() => setShowSidebar(!showSidebar)}
              />
            </AppBar> */}

            <div style={{
              backgroundImage: size === 'small' ? "url(/mobile-background.jpeg)": "url(/new-background.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: 0, 
              left: 0,
              zIndex: 1,
              transform: "scale(1.05)",
              filter: "grayscale(50%) blur(5px) saturate(120%) opacity(50%)",
            }}/>

            { locked ? 

              <Box direction='column' flex overflow="auto" min-height="min-content" style={{zIndex: 2}}>

                <Box animation={[{type: "fadeIn", delay: 200, duration: 1000}, {type: "slideUp", delay: 400, duration: 1000}]} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                  <Card width={size === 'small' ? 'medium': 'large'} background="white">
                    <CardBody pad="large" flex direction={size !== 'small' ? 'row': 'column'}>
                      <Box flex align="center" justify="center">
                        <Heading level="3">Enter the Passcode</Heading>
                        <TextInput id='last-name' name="last-name" value={passcode} onChange={(event) => {
                          setPasscode(event.target.value);
                          setInvalidPasscode(false);
                        }} />
                      </Box>
                      <Box flex align="center" justify="center">
                        <Button primary label="Submit" onClick={() => {
                          if (passcode === 'tkwed' || passcode === 'Tkwed' || passcode === 'TKWED') {
                            setLocked(false);
                            localStorage.setItem('a', 'a');
                          } else {
                            setInvalidPasscode(true);
                            setPasscode("");
                          }
                        }}/>
                        {invalidPasscode && <Paragraph color='red'>Invalid Passcode.</Paragraph>}
                      </Box>
                    </CardBody>
                  </Card>
                </Box>
              </Box>
              :
              
              showForm ? 

                <Box direction='column' flex overflow="auto" min-height="min-content" style={{zIndex: 2}}>    

                  <Box animation={{type: "fadeIn", delay: 0, duration: 1000}} flex align='center' justify='center' style={{'min-height': 'fit-content', marginTop: '3em', marginBottom:'1em'}}>
                    <Heading textAlign="center" level="2">RSVP</Heading>
                  </Box>  

                  <Box animation={{type: "fadeIn", delay: 1000, duration: 1000}} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                    <Card width={size === 'small' ? 'medium': 'large'} background="white">
                      <CardBody pad="small" flex direction='column' style={{margin: "3em"}}>
                        <Box flex="shrink" align="left" style={{marginBottom: "1em"}}>
                          <Button icon={<Icons.FormPrevious />} onClick={() => setShowForm(false)} label="Back"/>
                        </Box>

                        { submitted ? 
                        
                          <Box flex align="center" justify="center">
                            <Paragraph size="medium" textAlign="center">{submitError ? "There was an error submitting your RSVP. Please refresh and try again or contact us directly." : "Thank You!"}</Paragraph>
                          </Box>
                          :
                          <Fragment>
                            <Box flex align="center" justify="center">
                              <Paragraph size="medium" textAlign="center">Please complete the following details.</Paragraph>
                            </Box>
                            <Form 
                              value={form} 
                              onChange={nextValue => setForm(nextValue)} 
                              onReset={() => setForm({})} 
                              onSubmit={async ({ value }) => {
                                setSubmitting(true);
                                const result = await postRSVP(value);
                                setSubmitting(false);
                                setSubmitted(true);
                                if (result === false) {
                                  setSubmitError(true);
                                }
                              }}
                              validate="submit"
                            >
                              <FormField name="first-name" htmlFor="first-name" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }} label="First Name">
                                <TextInput id='first-name' name="first-name"/>
                              </FormField>
                              <FormField name="last-name" htmlFor="last-name" label="Last Name" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }}>
                                <TextInput id='last-name' name="last-name" />
                              </FormField>
                              <FormField name="email" htmlFor="email" label="Email"  validate={{ regexp: /^.+@.+$/i }}>
                                <TextInput type='email' id='email' name="email" />
                              </FormField>
                              <FormField name="attending" htmlFor="attending" label="Attending" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }}>
                                <RadioButtonGroup name="attending" id="attending" options={['Yes', 'No']} />
                              </FormField>
                              { form.attending === "Yes" ?
                                <Fragment>
                                  <FormField name="attending-option" htmlFor="attending-option" label="What will you be attending?" validate={{ regexp: /^[\S\s]+$/i }}>
                                    <Select name="attending-option" id="attending-option" options={['Ceremony Only', 'Ceremony and Reception']}/>
                                  </FormField>
                                  <FormField name="partner" htmlFor="partner" label="Is your partner attending?" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }}>
                                    <RadioButtonGroup name="partner" id="partner" options={['Yes', 'No']} />
                                  </FormField>
                                  { form.partner === "Yes" ? 
                                    <Fragment>
                                      <FormField name="partner-first-name" htmlFor="partner-first-name" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }} label="Partner's First Name">
                                        <TextInput id='partner-first-name' name="partner-first-name"/>
                                      </FormField>
                                      <FormField name="partner-last-name" htmlFor="partner-last-name" label="Partner's Last Name" validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }}>
                                        <TextInput id='partner-last-name' name="partner-last-name" />
                                      </FormField>
                                    </Fragment> : <Fragment/>
                                  }
                                  <FormField name="children" htmlFor="children" label="Number of Children">
                                    <Select name="children" id="children" options={['None', 'One', 'Two', 'Three', 'Four']}/>
                                  </FormField>
                                  { [...Array(childMap[form.children]).keys()].map(childNumber => 
                                    <Fragment>
                                      <FormField name={`child-${childNumber+1}-first-name`} htmlFor={`child-${childNumber+1}-first-name`} validate={{ regexp: /^[a-zA-Z]+[a-zA-Z\s]*$/i }} label={`Child ${childNumber+1} Name`}>
                                        <TextInput id={`child-${childNumber+1}-first-name`} name={`child-${childNumber+1}-first-name`}/>
                                      </FormField> 
                                      <FormField name={`child-${childNumber+1}-age`} htmlFor={`child-${childNumber+1}-age`} validate={{ regexp: /^[\S\s]+$/i }} label={`Child ${childNumber+1} Age`}>
                                        <TextInput id={`child-${childNumber+1}-age`} name={`child-${childNumber+1}-age`}/>
                                      </FormField>
                                    </Fragment> ) }
                                  { form["attending-option"] === 'Ceremony and Reception' ? 
                                    <FormField name="diet" htmlFor="diet" label="Dietary Requirements">
                                      <TextArea name="diet" id="diet" />
                                    </FormField>
                                    : <Fragment/>
                                  }
                                  <FormField name="questions" htmlFor="questions" label="Any Questions?">
                                    <TextArea name="questions" id="questions" />
                                  </FormField>
                                </Fragment> : <Fragment/>
                              }
                              <Box direction="row" gap="medium" style={{marginTop: '2em'}}>
                                <Button type="submit" primary label="Submit" disabled={submitting} icon={submitting ? <Spinner/> : undefined}/>
                                {/* <Button type="reset" label="Reset" /> */}
                              </Box>
                            </Form>
                          </Fragment>
                        }
                      </CardBody>
                    </Card>
                  </Box>

                </Box>

                :

                <Box direction='column' flex overflow="auto" min-height="min-content" style={{zIndex: 2}}>
                  
                  <Box animation={{type: "fadeIn", delay: 0, duration: 1000}} flex align='center' justify='center' style={{'min-height': 'fit-content', marginTop: '3em', marginBottom:'1em'}}>
                    <Heading textAlign="center" level="3">Tim & Kaylah</Heading>
                  </Box>

                  <Box animation={{type: "fadeIn", delay: 200, duration: 1000}} flex align='center' justify='center' style={{'min-height': 'fit-content', marginTop: '1em', marginBottom:'1em'}}>
                    <Heading textAlign="center" level="1">We're Getting Married</Heading>
                  </Box>

                  <Box animation={{type: "fadeIn", delay: 300, duration: 1000}} flex align='center' justify='center' style={{'min-height': 'fit-content', marginTop: '1em', marginBottom:'1em'}}>
                    <Heading textAlign="center" level="2">Sunday November 20th 2022</Heading>
                  </Box>

                  <Box animation={[{type: "fadeIn", delay: 400, duration: 1000}, {type: "slideUp", delay: 400, duration: 1000}]} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                    <Card width={size === 'small' ? 'medium': 'large'} background="white">
                      <CardBody pad="small" flex direction={size !== 'small' ? 'row': 'column'}>
                        <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                          <Heading level={size === 'small' ? '2': '3'}>Welcome</Heading>
                          <Paragraph size="small" textAlign="center">We'll be getting married at 3pm on Sunday,<br/>the 20th of November 2022.<br/><br/>We hope you'll be able to join us.</Paragraph>
                        </Box>
                        <Box flex align="center" justify="center">
                          {/* <Button primary label="RSVP Now" onClick={() => setShowForm(true)}/>
                          <Paragraph size="small" textAlign="center">RSVP closes <b>September 1st</b></Paragraph> */}
                          <Paragraph size="small" textAlign="center">RSVP closed</Paragraph>
                        </Box>
                      </CardBody>
                    </Card>
                  </Box>

                  <Box animation={[{type: "fadeIn", delay: 600, duration: 1000}, {type: "slideUp", delay: 600, duration: 1000}]} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                    <Card width={size === 'small' ? 'medium': 'large'} background="white" style={{'min-height': 'fit-content'}}>
                      <CardBody pad="small" flex direction={size !== 'small' ? 'row': 'column'}>
                        <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                          <Heading level={size === 'small' ? '2': '3'}>Ceremony and Reception</Heading>
                          <Heading level={size === 'small' ? '2': '3'}>Darlington Estate</Heading>
                          <Paragraph size="small">1495 Nelson Rd, Darlington, Perth WA 6070</Paragraph>
                        </Box>
                        <Box height="18em" style={{'min-height': '13em'}} flex justify='center' hoverIndicator={{elevation: "small"}} onClick={()=> window.open("https://goo.gl/maps/RvcLahcbnaKNstE67", "_blank")} background={{
                            size: "contain",
                            image: "url(/map.jpeg)"
                          }}>
                          {/* <img 
                            src="/map.jpeg" 
                            alt="map" 
                            style={{cursor: "pointer"}}
                          /> */}
                        </Box>
                      </CardBody>
                    </Card>
                  </Box>

                  <Box animation={[{type: "fadeIn", delay: 800, duration: 1000}, {type: "slideUp", delay: 800, duration: 1000}]} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                    <Card width={size === 'small' ? 'medium': 'large'} background="white">
                      <CardBody pad="small" flex direction={size !== 'small' ? 'column': 'column'}>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{'min-height': 'fit-content'}}>
                          <Heading level={size === 'small' ? '2': '3'}>Information</Heading>
                        </Box>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{...{'min-height': 'fit-content'}, ...(size !== 'small' ? {alignItems: 'baseline'} : {})}}>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Times</Heading>
                            <Paragraph size="small" textAlign="center">Guests can arrive from 2:30pm<br/>Ceremony commences 3pm<br/>Drinks, dinner and dancing<br/>Bar closes 10pm<br/>Guest departure 10:30pm latest.</Paragraph>
                          </Box>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Dress</Heading>
                            <Paragraph size="small" textAlign="center">We'd love to see you get fancy with us! Ladies can wear floor-length, tea-length or midi formal dresses, we'd recommend no stilettos as it is a rustic venue with brick floor. Gentlemen should wear a suit and tie with or without jacket, please no jeans.</Paragraph>
                          </Box>
                        </Box>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{...{'min-height': 'fit-content'}, ...(size !== 'small' ? {alignItems: 'baseline'} : {})}}>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Food & Drink</Heading>
                            <Paragraph size="small" textAlign="center">Three course sit down meal of Darlington Estate's award winning cuisine.<br/>Drinks feature Darlington Estate's premium wines.</Paragraph>
                          </Box>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Cards & Gifts</Heading>
                            <Paragraph size="small" textAlign="center">We will have a wishing well and gifts table upon entry.</Paragraph>
                          </Box>
                        </Box>
                      </CardBody>
                    </Card>
                  </Box>

                  <Box animation={[{type: "fadeIn", delay: 800, duration: 1000}, {type: "slideUp", delay: 800, duration: 1000}]} flex align='center' justify='center' style={{'min-height': 'fit-content'}}>
                    <Card width={size === 'small' ? 'medium': 'large'} background="white">
                      <CardBody pad="small" flex direction={size !== 'small' ? 'column': 'column'}>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{'min-height': 'fit-content'}}>
                          <Heading level={size === 'small' ? '2': '3'}>Q&A</Heading>
                        </Box>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{...{'min-height': 'fit-content'}, ...(size !== 'small' ? {alignItems: 'baseline'} : {})}}>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Children</Heading>
                            <Paragraph size="small" textAlign="center">Children are welcome however the venue requires parent supervision at all times.</Paragraph>
                          </Box>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Plus One's</Heading>
                            <Paragraph size="small" textAlign="center">Plus One's are limited to partners only.</Paragraph>
                          </Box>
                        </Box>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{...{'min-height': 'fit-content'}, ...(size !== 'small' ? {alignItems: 'baseline'} : {})}}>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Where to Stay</Heading>
                            <Paragraph size="small" textAlign="center">If you are travelling and looking for accommodation, the venue is located 35 minutes from the CBD, 30 minutes from Crown Towers and if you're looking for something closer we also recommend looking at AirBnB at surrounding suburbs including Hovea, Parkerville and Mundaring. </Paragraph>
                          </Box>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Transport</Heading>
                            <Paragraph size="small" textAlign="center">Darlington Estate offers free parking at the venue for those wishing to responsibly drive. Rideshare services such as Uber will happily take you to Darlington Estate however most will not pick up from this venue. We recommend pre-booking or organising a taxi on the night to return home.</Paragraph>
                          </Box>
                        </Box>
                        <Box flex align="center" direction={size !== 'small' ? 'row': 'column'} justify="center" style={{...{'min-height': 'fit-content'}, ...(size !== 'small' ? {alignItems: 'baseline'} : {})}}>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Ceremony</Heading>
                            <Paragraph size="small" textAlign="center">Please no phones or photography at the ceremony.</Paragraph>
                          </Box>
                          <Box flex align="center" justify="center" style={{'min-height': 'fit-content'}}>
                            <Heading level={size === 'small' ? '2': '3'} style={{marginBlockEnd: '.3em'}}>Questions</Heading>
                            <Paragraph size="small" textAlign="center">Get in touch with us if you have any questions.</Paragraph>
                          </Box>
                        </Box>
                      </CardBody>
                    </Card>
                  </Box>

                  {(!showSidebar || size !== 'small') ? (
                    <Collapsible direction="horizontal" open={showSidebar}>
                      <Box
                        flex
                        width='medium'
                        background='light-2'
                        elevation='small'
                        align='center'
                        justify='center'
                      >
                        Navigation Menu
                      </Box>
                    </Collapsible>
                  ): (
                    <Layer>
                      <Box
                        background='light-2'
                        tag='header'
                        justify='end'
                        align='center'
                        direction='row'
                      >
                        <Button
                          icon={<Icons.FormClose />}
                          onClick={() => setShowSidebar(false)}
                        />
                      </Box>
                      <Box
                        fill
                        background='light-2'
                        align='center'
                        justify='center'
                      >
                        sidebar
                      </Box>
                    </Layer>
                  )}
                </Box>
            }
          </Box>
        )}
        </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
